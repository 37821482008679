import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Drawer,
  Button,
  Dropdown,
  MenuProps,
  Space,
  Modal,
  Form,
  Input,
  Image,
  Upload,
  message,
  Switch,
  Select,
} from "antd";
import {
  ShoppingCartOutlined,
  MenuOutlined,
  UserOutlined,
  LogoutOutlined,
  EditOutlined,
  DashboardOutlined,
  PlusOutlined,
  UploadOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./header-footer.css";
import BottomNav from "./BottomNav";
import useApiRequest from "../../utils/helperFunctions";
import { useForm } from "antd/es/form/Form";

const { Header } = Layout;
const { SubMenu } = Menu;

const Navbar = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo]: any = useState({});
  const [form] = useForm();
  const [profileUrl, setProfileUrl] = useState("/assets/images/dummy-user.jpg");
  const [profileUploaded, setProfileUploaded] = useState(false);
  let userDetailsFetched = false;
  const token = localStorage.getItem("accessToken");

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const menuItems = [
    {
      key: "1",
      title: "Home",
      class: "cart-icon-header",
      link: "/",
    },
    {
      key: "2",
      title: "Services",
      submenu: [
        {
          key: "VS",
          title: "Virtual Staging",
          link: "/services/residential/virtual-staging",
        },
        {
          key: "VR",
          title: "Virtual Renovation",
          link: "/services/residential/virtual-renovation",
        },
        {
          key: "VC",
          title: "Virtual Commercial",
          link: "/services/commercial/virtual-staging",
        },
        {
          key: "PE",
          title: "Photo Editing",
          link: "/services/pe/image-enhancement",
        },
        {
          key: "FP",
          title: "Floor Plan",
          link: "/services/residential/floor-plan",
        },
        {
          key: "GD",
          title: "Graphic Designing",
          link: "/services/graphic-designing",
        },
      ],
    },
    {
      key: "3",
      title: "Resources",
      submenu: [
        { key: "OG", title: "Our Galllery", link: "/gallery" },
        { key: "review", title: "Review", link: "/reviews" },
        { key: "blog", title: "Blog", link: "/blogs" },
      ],
    },
    {
      key: "4",
      title: "Contact",
      submenu: [
        { key: "CU", title: "Contact with us", link: "/contact-us" },
        { key: "BQ", title: "Bulk Quotes", link: "/order-bulk-quotes" },
      ],
    },
    {
      key: "5",
      title: "About",
      link: "/about-us",
      // submenu: [{ key: "AU", title: "About Us", link: "/about-us" }],
    },

    {
      key: "8",
      icon: <UserOutlined />,
      title: "User",
      submenu: [
        { key: "PR", title: "Profile" },
        { key: "DS", title: "Dashboard", link: "/user-dashboard" },
        { key: "LG", title: "Logout" },
      ],
    },
    // {
    //   key: "7",
    //   icon: <img className="cart-logo" src="/assets/images/cart-icon.png" />,
    //   title: "Cart",
    //   class: "cart-icon-header",
    //   link:'/cart'
    // },
    {
      key: "9",
      title: "Sign in",
      link: "/login",
    },
  ];

  const getSelectedKeys = () => {
    for (let item of menuItems) {
      if (item.link === location.pathname) {
        return [item.key];
      }
      if (item.submenu) {
        for (let subItem of item.submenu) {
          if (subItem.link === location.pathname) {
            return [subItem.key, item.key];
          }
        }
      }
    }
    return [];
  };

  const selectedKeys = getSelectedKeys();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/";
  };

  const [scrolled, setScrolled] = useState(false);
  const { apiRequest } = useApiRequest();
  const locationPath = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        // Adjust this value as needed
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    console.log("click", e);
  };

  const handleProfile = async () => {
    try {
      const resp = await apiRequest({
        url: "profile",
        method: "GET",
        showMessage: false,
        payload: {},
      });
      // console.log(resp);

      if (resp.status === 200) {
        setUserInfo(resp.data);
        form.setFieldsValue(resp.data);
        userDetailsFetched = true;
      }
    } catch (errors) {
      console.error("Failed to fetch service list", errors);
    }
  };
  // if (locationPath.pathname == "/" && !userDetailsFetched) {
  //   handleProfile();
  // }
  useEffect(() => {
    if (isProfile) {
      handleProfile();
    }
  }, [isProfile]);

  const onFinish = async () => {
    try {
      let payload = form.getFieldsValue();

      if (profileUploaded) {
        payload.user_avatar = profileUrl.toString();
      }

      const resp = await apiRequest({
        url: "/profile-update",
        method: "PUT",
        showMessage: false,
        payload: payload,
      });
      // console.log(resp);
      if (resp.status === 200) {
        message.success("Profile updated successfully");
        setIsProfile(false);
      }
    } catch (errors) {
      console.error("Failed to update profile", errors);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Profile",
      key: "1",
      icon: <UserOutlined />,
      // onClick: () => navigate('/profile'),
      onClick: () => setIsProfile(true),
    },
    {
      label: "Dashboard",
      key: "2",
      icon: <DashboardOutlined />,
      onClick: () => navigate("/user-dashboard"),
      // onClick: () => setIsProfile(true),
    },
    {
      label: "Logout",
      key: "3",
      onClick: () => handleLogout(),
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleUploadMedia = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    return fetch(`${process.env.REACT_APP_API_URL}/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust the token retrieval as needed
      },
      body: formData,
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  console.log("handleUploadMedia", handleUploadMedia);

  const handleUploadChange = ({ fileList }: any) => {
    console.log("Uploading", fileList);

    if (fileList[0]?.status === "done") {
      setProfileUrl(fileList[0]?.response?.file_url);
      setProfileUploaded(true);
    } else if (fileList[0]?.status === "error") {
      message.error(`${fileList[0]?.name} file upload failed.`);
    }
  };

  return (
    <>
      <Header
        className={`main-header ${
          props?.bgWhite === true ? "header_scrolled" : ""
        } ${scrolled ? "header_scrolled" : ""} ${
          props?.static && "static-header"
        }`}
      >
        <div className="logo">
          <Link to="/" className="logoTransparent">
            <img src="/assets/images/WHITE_BOTH.svg" alt="Stagobox" />
          </Link>
          <Link to="/" className="logoWhite">
            <img src="/assets/images/fullLogoBlack.svg" alt="Stagobox" />
          </Link>
        </div>
        <Menu
          theme="light"
          mode="horizontal"
          selectedKeys={selectedKeys}
          className="main-navbar"
          style={{
            flex: 1,
            minWidth: 0,
            justifyContent: "right",
            border: 0,
            alignItems: "center",
          }}
        >
          {menuItems?.map((item) =>
            item.submenu ? (
              item.key != "8" ? (
                <SubMenu
                  className="header-child-menu"
                  key={item.key}
                  title={item.title}
                >
                  {item.submenu.map((subItem) => (
                    <Menu.Item key={subItem.key}>
                      {subItem?.link ? (
                        <Link to={subItem.link}>{subItem.title}</Link>
                      ) : (
                        <div
                          key={subItem.key}
                          onClick={() => {
                            localStorage.removeItem("accessToken");
                            localStorage.removeItem("refreshToken");
                            window.location.href = "/";
                            console.log("logout");
                          }}
                        >
                          {subItem.title}
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : token ? (
                <SubMenu
                  className="header-child-menu"
                  key={item.key}
                  title={userInfo.username}
                >
                  {item.submenu.map((subItem) => (
                    <Menu.Item key={subItem.key}>
                      {subItem?.link ? (
                        <Link to={subItem.link}>{subItem.title}</Link>
                      ) : subItem.key == "PR" ? (
                        <div
                          key={subItem.key}
                          onClick={() => {
                            setIsProfile(true);
                          }}
                        >
                          {subItem.title}
                        </div>
                      ) : (
                        <div
                          key={subItem.key}
                          onClick={() => {
                            localStorage.removeItem("accessToken");
                            localStorage.removeItem("refreshToken");
                            window.location.href = "/";
                            console.log("logout");
                          }}
                        >
                          {subItem.title}
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : null
            ) : item.key != "9" ? (
              <Menu.Item key={item.key}>
                <Link to={item.link}>{item.icon}</Link>
                <span className={item.class}>
                  <Link to={item.link}>{item.title}</Link>
                </span>
              </Menu.Item>
            ) : !token ? (
              <Menu.Item key={item.key}>
                <Link to={item.link}>{item.icon}</Link>
                <span className={item.class}>
                  <Link to={item.link}>{item.title}</Link>
                </span>
              </Menu.Item>
            ) : null
          )}
        </Menu>

        {/* {token ? (
          // <Button
          //   onClick={() => handleLogout()}
          //   className="theme-btn"
          //   style={{ lineHeight: 0 }}
          // >
          //   Logout
          // </Button>
          <Space wrap>
            <Dropdown menu={menuProps} placement="bottom">
              <Button
                style={{ background: "transparent", border: 0, padding: 0 }}
              >
                <UserOutlined />
              </Button>
            </Dropdown>
          </Space>
        ) : (
          <Link to="/cart" title="cart">
            <img className="cart-logo" src="/assets/images/cart-icon.png" />
          </Link>
          // <Link style={{ color: "var(--primary)" }} to="/login">
          //   Sign in
          // </Link>
        )} */}
        {token ? (
          <Link
            to={token ? "/cart" : "/login"}
            title={token ? "Cart" : "Login first"}
          >
            <img className="cart-logo" src="/assets/images/cart-icon.png" />
          </Link>
        ) : null}

        <MenuOutlined className="menu-icon" onClick={showDrawer} />
      </Header>

      <Drawer
        title="Menu"
        placement="left"
        onClose={onClose}
        open={visible}
        width="60%"
        className="main-drawer"
      >
        <Menu selectedKeys={selectedKeys} mode="inline">
          {menuItems.map((item) =>
            item.submenu ? (
              <SubMenu key={item.key} title={item.title}>
                {item.submenu.map((subItem) => (
                  <Menu.Item key={subItem.key}>
                    {subItem?.link ? (
                      <Link to={subItem.link}>{subItem.title}</Link>
                    ) : (
                      <div
                        key={subItem.key}
                        onClick={() => {
                          localStorage.removeItem("accessToken");
                          localStorage.removeItem("refreshToken");
                          window.location.href = "/";
                          console.log("logout");
                        }}
                      >
                        {subItem.title}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.key}>
                <Link onClick={onClose} to={item.link}>
                  {item.title}
                </Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Drawer>

      <Modal
        title="Profile Information"
        open={isProfile}
        footer={false}
        onCancel={() => setIsProfile(false)}
        destroyOnClose
      >
        <div className="profile-info-box">
          <EditOutlined
            onClick={() => setIsEditProfile(!isEditProfile)}
            style={{
              color: "var(--primary)",
              fontSize: "26px",
              position: "absolute",
              top: "50px",
              right: "30px",
            }}
          />
          <div className="profile-img mt-4 mb-4">
            <Image src={profileUrl} alt="User Profile" />
          </div>
          <div className="profile-details">
            <div className="text-right">
              <Upload
                onChange={handleUploadChange}
                customRequest={({ file, onSuccess, onError }) => {
                  handleUploadMedia(file).then(onSuccess).catch(onError);
                }}
                showUploadList={false}
                maxCount={1}
              >
                <Button disabled={!isEditProfile} icon={<UploadOutlined />}>
                  Update profile picture
                </Button>
              </Upload>
            </div>
            {/* <h2>User Details</h2> */}
            <Form
              layout="vertical"
              form={form}
              initialValues={userInfo}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Name"
                name="username"
                rules={[
                  { required: true, message: "Please enter your Username." },
                ]}
              >
                <Input
                  placeholder="Enter your name"
                  disabled={!isEditProfile}
                />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your Email." },
                ]}
              >
                <Input placeholder="Enter your email" disabled />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Phone number.",
                  },
                  {
                    pattern: /^\d{10}$/,
                    message: "Phone number must be exactly 10 digits",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your phone number"
                  disabled={!isEditProfile}
                />
              </Form.Item>
              {/* <Form.Item label="Address" name="address">
                <Input
                  placeholder="Enter your address"
                  disabled={!isEditProfile}
                />
              </Form.Item> */}
              {/* <Form.Item label="Password">
                  <Input.Password placeholder="Enter your password" />
                </Form.Item> */}
              {/* <Form.Item label="Confirm Password">
                  <Input.Password placeholder="Confirm your password" />
                </Form.Item> */}
              <Form.Item
                name="company"
                label="Industry"
                className="field"
                rules={[
                  {
                    required: true,
                    message: "Select your Industry!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select an Industry"
                  disabled={!isEditProfile}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Real Estate Agent / Broker",
                      label: "Real Estate Agent / Broker",
                    },
                    {
                      value: "Developer / Builder / Construction",
                      label: "Developer / Builder / Construction",
                    },
                    {
                      value: "Property Management",
                      label: "Property Management",
                    },
                    {
                      value: "Short Stay Accomondation",
                      label: "Short Stay Accomondation",
                    },
                    { value: "Photographer", label: "Photographer" },
                    { value: "Ecommerce", label: "Ecommerce" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </Form.Item>

              <Form.Item>
                <button type="submit" className="theme-btn w-100 mt-4">
                  Update Profile
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
