import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function TopHomeButton() {
    let [hide, setHide] = useState(false)
    let location = useLocation()
    window.addEventListener('scroll',()=>{
        window.scrollY == 0?
        setHide(true):
        setHide(false)
    })
    
  return (
    <>
      <div className="utility" 
       
      >
        <button
        role="button"
          title="Top"
          onClick={()=>{
            window.scrollTo({
                top:0,
                behavior:"smooth"
            })
          }}
          className="top-btn"
          style={{  
                    transitionDuration:"0.2s",
                    width: "50px",
                    height: "50px",
                    position:"fixed",
                    zIndex:"1000",
                    borderRadius:"50%",
                    bottom:location.pathname=="/"?"10px":"70px",
                    right:"10px",
                    opacity:hide?0:1,
            }}
        >
          <img style={{filter:"invert()",transformOrigin:"center", transform:"rotate(-90deg)"}} src="/assets/images/TopArrow.svg" />
        </button>
        <Link role="button" title="Home" className="home-button" style={{
              transitionDuration:"0.2s",
              opacity:location.pathname == "/"?0:1,
              width: "50px",
              height: "50px",
              position:"fixed",
              zIndex:"1000",
              borderRadius:"50%",

              backgroundColor:"#f0f0f0",
              outline:"2px solid #666666",
              bottom:"10px",
              right:"10px"
        }} to="/">
            <img style={{filter:"invert()", transform:"translate(50%, 50%) scale(130%)"}} src="/assets/images/HomeIcon.svg"/>
        </Link>
      </div>
    </>
  );
}
export default React.memo(TopHomeButton);
