  import CaterIndustry from "../../Sections/CaterIndustry";
  import FirstTime from "../../Sections/FirstTime";
  import HowItWorks from "../../Sections/HowItWorks";
  import ServiceCard from "../../Sections/ServiceCard";
  import BottomNav from "../../Layout/BottomNav";
  import { useEffect } from "react";
  import Navbar from "../../Layout/Header";
  import WeCanDO from "../../Sections/WeCanDo";
  
  const PhotoEditingPage = (props: any) => {
    // useEffect(() => {
    //   props?.setUserDash(true);
    //   return () => props?.setUserDash(false);
    // }, [props]);
  
    return (
      <>
        {/* <Navbar static={true} /> */}
  
        <BottomNav />
  
        {/* <WeCanDO /> */}
  
        <div className="blur-background-box photo-editing-main">
          <div className="hp-container">
            <div className="page-title pt-5" data-aos="fade-up">
              <h1>Photo Editing</h1>
              <p>
              Stagobox’s Photo Editing service enhances property images with professional touch-ups including image enhancement, day-to-dusk transitions, object removal, and occupied-to-vacant conversions. By refining colors, adding dramatic twilight effects, removing distractions, and presenting clean, neutral spaces, we help properties stand out in listings and marketing materials. This service increases the visual appeal and marketability of your listings, attracts more potential buyers, and accelerates the sales process.
              </p>
            </div>
  
            <section className="rm-padtop" id="graphic-design-section">
              <h2 className="mb-5 mt-5 pb-5">
                <span className="underLine">Services we provide under Photo Editing</span>
                </h2>
  
              <ServiceCard
                imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
                imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
                description="We'll enhance the overall quality of your photos, making them sharper, more vibrant, and visually appealing."
                title="Image Enhancement"
                isPlaceOrder={true}
                classes={["mb-5"]}
                key={1}
                pageUrl="/services/business-card-form"
                price="1.5"
                perPhoto={false}
                listItems={[
                  <span>
                    <b>Quick turnaround time of 24-48 hours</b>
                  </span>,
                  <b>Super Rapid Delivery in just 12 hours</b>,
                  <span>
                    <b>Enjoy unlimited free revisions</b>
                  </span>,
                  <b>100% Customization Guaranteed </b>,
                  // <b>Free Unlimited Revisions</b>,
                  <b>Experience Professional Excellence</b>,
                ]}
              />
  
              <ServiceCard
                imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
                imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
                description="Transforms daytime photos into captivating, twilight-inspired photos, enhancing their visual appeal."
                title="Day To Dusk"
                classes={["mb-5"]}
                isPlaceOrder={true}
                perPhoto={false}

                key={1}
                pageUrl="/services/day-to-dusk-form"
                price="5"
                listItems={[
                    <span>
                    <b>Quick turnaround time of 24-48 hours</b>
                  </span>,
                  <b>Super Rapid Delivery in just 12 hours</b>,
                  <span>
                    <b>Enjoy unlimited free revisions</b>
                  </span>,
                  <b>100% Customization Guaranteed </b>,
                  // <b>Free Unlimited Revisions</b>,
                  <b>Experience Professional Excellence</b>,
                ]}
              />
  
              <div id="vector-design-gd">
              <ServiceCard
                imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
                imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
                description="Seamlessly erases unwanted elements, enhancing property images for a pristine presentation."
                title="Object/Item Removal"
                isPlaceOrder={true}
                perPhoto={false}

                key={1}
                pageUrl="/services/vector-design-form"
                price="8"
                classes={["mb-5"]}
                listItems={[
                    <span>
                    <b>Quick turnaround time of 24-48 hours</b>
                  </span>,
                  <b>Super Rapid Delivery in just 12 hours</b>,
                  <span>
                    <b>Enjoy unlimited free revisions</b>
                  </span>,
                  <b>100% Customization Guaranteed </b>,
                  // <b>Free Unlimited Revisions</b>,
                  <b>Experience Professional Excellence</b>,
                ]}
              />
              </div>
  
              <ServiceCard
                imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
                imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
                description="Converting an occupied space into a vacant one can enhance the property's cleanliness and often leads to increased demand."
                title="Occupied To Vacant"
                isPlaceOrder={true}
                perPhoto={false}
                key={1}
                pageUrl="/services/digital-display-form"
                price="15"
                classes={["mb-4"]}
                listItems={[
                  <span>
                    <b>Quick turnaround time of 24-48 hours</b>
                  </span>,
                  <b>Super Rapid Delivery in just 12 hours</b>,
                  <span>
                    <b>Enjoy unlimited free revisions</b>
                  </span>,
                  <b>100% Customization Guaranteed </b>,
                  // <b>Free Unlimited Revisions</b>,
                  <b>Experience Professional Excellence</b>,
                ]}
              />
  
            </section>
          </div>
  
          {/* ========== How it works ========== */}
          <HowItWorks />
  
          <div className="hp-container">
            {/* First time */}
            <div data-aos="fade-up">
              <FirstTime />
            </div>
            {/* Industry we are catering */}
            <div data-aos="fade-up">
              <CaterIndustry />
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default PhotoEditingPage;
  