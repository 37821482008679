import React, { useState, useEffect } from 'react';
import './paypal.css'



const queryParams = new URLSearchParams(window.location.search);

const url_orderId = queryParams.get('OrderId');
const url_service = queryParams.get('Service');
const url_amount = queryParams.get('Amount');
const url_orderType = queryParams.get('OrderType');
const url_countryCode = queryParams.get('CC') || 'USD';

const BreakDownContent = [
    {
        text: 'Amount:',
        main: '$20.00 USD',
        icon: 'fa fa-money',
    },
    {
        text: 'Date:',
        main: 'Nov 5',
        icon: 'fa fa-calendar',
    },
    {
        text: 'Issuer:',
        main: 'Stagobox',
        icon: 'fa fa-star-o',
    },
    {
        text: 'Invoice No:',
        main: '878NJDBW8Y9',
        icon: 'fa fa-list-alt',
    },
];

const BreakDownEntry = ({ details }) => (
    <li>
        <span className={details.icon}></span>
        <div className="list-content">
            <p>
                {details.text}
                <span className="list-bold">{details.main}</span>
            </p>
        </div>
    </li>
);

const Breakdown = ({ breakdown }) => (
    <div className="card-breakdown">
        <div className="card-breakdown--header">
            <p>Receipt for</p>
            <h2>Stagobox</h2>
        </div>
        <ul className="card-breakdown--list">
            {breakdown.map((item, index) => (
                <BreakDownEntry key={index} details={item} />
            ))}
        </ul>
    </div>
);


const OverviewHeader = ({ logo }) => {
    const [currentTime, setCurrentTime] = useState({
        date: '',
        time: '',
    });

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const formattedDate = now.toLocaleDateString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
            });
            const formattedTime = now.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                // second: '2-digit',
                hour12: false,
                timeZoneName: 'short',
            });
            const [time, timeZone] = formattedTime.split(' ');
            setCurrentTime({ date: formattedDate, time: `${time} ${timeZone}` });
        };

        updateTime(); // Update immediately
        const intervalId = setInterval(updateTime, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    return (
        <>
        <div className="overview-header">
            <img className="logo" src={logo} alt="Logo" />
            <div className="timestamp">
                <span>{currentTime.date}</span>
                <span>{currentTime.time}</span>
            </div>
            {/* <hr /> */}
        </div>
        <hr />
        </>
    );
};

const PurchaseOverview = ({ product, value }) => (
    <div className="purchase-overview">
        <h3>{product}</h3>
        <p>Total: {url_amount}</p>
    </div>
);

const OverviewBody = ({ product, value, name, merchant, merchantEmail }) => (
    <div className="overview-body">
        <div className="purchase-overview">
        <h3>{product}</h3>
        <p>Total: {url_amount || value}</p>
    </div>
        <div className="user-info">
            <p className="user-info-name">Hello {name || 'User'},</p>
            <span>Here is the payment link below : </span>
            <div id="paypal-button-container"></div>
            {/* <p className="user-info-text">
                You sent a payment of <span>{value}</span> to {merchant}. (
                <a href={`mailto:${merchantEmail}`}>{merchantEmail}</a>)
            </p> */}

        </div>
        <div className="descriptor">
            <p>It may take a few moments for this transaction to appear in your account</p>
        </div>
    </div>
);

const OverviewFooter = ({ invoiceId }) => (
    <footer className="overview-footer">
        <span className="site">
            <a href="https://halvorson.digital/blog" target="_blank" rel="noopener noreferrer">
                www.paypal.com/help
            </a>
        </span>
        {/* <span className="invoice-id">Invoice ID: {invoiceId}</span> */}
    </footer>
);

const Overview = (props) => (
    <div className="card-overview">
        <OverviewHeader logo="https://s3-us-west-2.amazonaws.com/s.cdpn.io/553128/PayPal.svg" />
        <OverviewBody {...props} />
        <OverviewFooter invoiceId={props.invoiceId} />
    </div>
);

const Paypal = (props) => {
    const [breakdown, setBreakdown] = useState([]);
    const [isSdkLoaded, setIsSdkLoaded] = useState(false);

    useEffect(() => {
        const dynamicBreakdown = [
            {
                text: 'Date:',
                main: props?.info?.date || new Date().toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                }),
                icon: 'fa fa-calendar',
            },
            {
                text: 'Issuer:',
                main: 'Stagobox',
                icon: 'fa fa-star-o',
            },
            {
                text: 'Invoice No:',
                main: props?.info?.orderId || url_orderId || 'N/A',
                icon: 'fa fa-list-alt',
            },
            {
                text: 'Amount:',
                main: `$ ${props?.info?.totalPrice || url_amount || '0.00'} USD`,
                icon: 'fa fa-money',
            },
        ];

        setBreakdown(dynamicBreakdown);
    }, [props]);


    console.log("Payment details ===>>>", props)

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.paypal.com/sdk/js?client-id=AY0N2HkFDI-HGPG-FzaZ_9EkG4tQ6lwPbgHljb2dzsSOjkD3kfdVoYoLLGfQWrmZfviqywd_wHHJikgk';
        script.async = true;
        script.onload = () => {
            setIsSdkLoaded(true);
        };
        script.onerror = () => {
            console.error('PayPal SDK script failed to load.');
        };
        document.body.appendChild(script);

        return () => {
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    useEffect(() => {
        if (isSdkLoaded && window.paypal) {
            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            description: 'Your product description',
                            amount: {
                                currency_code: props?.info?.countryCode || url_countryCode,
                                value: props?.info?.totalPrice || url_amount
                            }
                        }]
                    });
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    console.log(order);
                },
                onError: (err) => {
                    console.error('PayPal Buttons Error:', err);
                }
            }).render('#paypal-button-container');
        }
    }, [isSdkLoaded]);

    useEffect(() => {
        setBreakdown(BreakDownContent);
    }, []);

    return (
        <div className=''>
            <div className="paypal-card">
                {url_amount ? <></> : <Breakdown breakdown={breakdown} />}
                <Overview
                    product="Stagobox Account"
                    merchant="Stagobox"
                    merchantEmail="paypal@stagobox.com"
                    name={props?.details?.name || url_service}
                    value={`$ ${props?.info?.totalPrice || url_amount} USD`}
                    invoiceId={`$ ${props?.info?.orderId || url_orderId}`}
                />
            </div>
        </div>
    );
};

export default Paypal;
