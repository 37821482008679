import CommercialStagingForm from "./CommercialStagingForm"

const DigitalDisplayForm = () => {
    return (
        <>
            <CommercialStagingForm unitPrice={8} />
        </>
    )
}

export default DigitalDisplayForm;