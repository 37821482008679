import { useState } from "react";
import { Carousel } from "antd";
import { useMediaQuery } from "react-responsive";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import Marquee from "react-fast-marquee";

const FlipReviewGrid = () => {
  // Initializing an array of states for the 6 cards
  const [flippedStates, setFlippedStates] = useState(Array(6).fill(false));

  // Function to handle flip, takes an index to know which card to flip
  const handleFlip = (index: number) => {
    const newFlippedStates = [...flippedStates];
    newFlippedStates[index] = !newFlippedStates[index];
    setFlippedStates(newFlippedStates);
  };

  // Media query to detect mobile screen
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const renderFlipCard = (
    index: number,
    frontContent: JSX.Element,
    backContent: JSX.Element
  ) => (
    <div className="flipper" onClick={() => handleFlip(index)}>
      <div
        className={`flipper-card ${
          flippedStates[index] ? "flipper-is-flipped" : ""
        }`}
      >
        <div className="flipper-front">{frontContent}</div>
        <div className="flipper-back">{backContent}</div>
      </div>
    </div>
  );

  const cardData = [
    {
      index: 0,
      frontContent: (
        <div className="review-flip-card">
          <div className="reviewImage">
            <img src="/assets/images/card-image.png" alt="card" />
          </div>
          <div className="p-2">
            <div className="clientDetails">
              <img src="/assets/images/customer.jpg" alt="" />
              <div>
                <span className="name">Hannah Schmitt</span>
                <span className="designation">Lead Designer</span>
              </div>
            </div>
            <div className="reviewText">
              Virtual Staging with Stagobox: "Stagobox virtual staging service
              has been a lifesaver for my real estate business. Their attention
              to detail and ability to create realistic virtual furniture
              placements have significantly enhanced the appeal of my listings.
              "
            </div>
          </div>
        </div>
      ),
      backContent: (
        <div className="review-flip-card">
          <div className="reviewImage">
            <img src="/assets/images/card-image.png" alt="card" />
          </div>
          <div className="p-2">
            <div className="clientDetails">
              <img src="/assets/images/customer.jpg" alt="" />
              <div>
                <span className="name">Hannah Schmitt</span>
                <span className="designation">Lead Designer</span>
              </div>
            </div>
            <div className="reviewText">
              Virtual Renovation by Stagobox: "I can't thank Stagobox enough for
              their virtual renovation service. They transformed my outdated
              property into a modern masterpiece with just a few clicks."
            </div>
          </div>
        </div>
      ),
    },
    {
      index: 1,
      frontContent: (
        <div className="review-flip-card">
          <div className="reviewImage">
            <img src="/assets/images/card-image.png" alt="card" />
          </div>
          <div className="p-2">
            <div className="clientDetails">
              <img src="/assets/images/customer.jpg" alt="" />
              <div>
                <span className="name">Hannah Schmitt1</span>
                <span className="designation">Lead Designer</span>
              </div>
            </div>
            <div className="reviewText">
              Virtual Staging with Stagobox: "Stagobox virtual staging service
              has been a lifesaver for my real estate business. Their attention
              to detail and ability to create realistic virtual furniture
              placements have significantly enhanced the appeal of my
              listings.tagobox virtual staging service has been a lifesaver for
              my real estate business. Their attention to detail and ability to
              create realistic virtual furniture placements have significantly
              enhanced the appeal of my listings."
            </div>
          </div>
        </div>
      ),
      backContent: (
        <div className="review-flip-card">
          <div className="reviewImage">
            <img src="/assets/images/card-image.png" alt="card" />
          </div>
          <div className="p-2">
            <div className="clientDetails">
              <img src="/assets/images/customer.jpg" alt="" />
              <div>
                <span className="name">Hannah Schmitt</span>
                <span className="designation">Lead Designer</span>
              </div>
            </div>
            <div className="reviewText">
              Virtual Renovation by Stagobox: "I can't thank Stagobox enough for
              their virtual renovation service. They transformed my outdated
              property into a modern masterpiece with just a few clicks.tagobox
              virtual staging service has been a lifesaver for my real estate
              business. Their attention to detail and ability to create
              realistic virtual furniture placements have significantly enhanced
              the appeal of my listings."
            </div>
          </div>
        </div>
      ),
    },
    {
      index: 2,
      frontContent: (
        <div className="review-flip-card">
          <div className="reviewImage">
            <img src="/assets/images/card-image.png" alt="card" />
          </div>
          <div className="p-2">
            <div className="clientDetails">
              <img src="/assets/images/customer.jpg" alt="" />
              <div>
                <span className="name">Hannah Schmitt</span>
                <span className="designation">Lead Designer</span>
              </div>
            </div>
            <div className="reviewText">
              Virtual Staging with Stagobox: "Stagobox virtual staging service
              has been a lifesaver for my real estate business. Their attention
              to detail and ability to create realistic virtual furniture
              placements have significantly enhanced the appeal of my
              listings.tagobox virtual staging service has been a lifesaver for
              my real estate business. Their attention to detail and ability to
              create realistic virtual furniture placements have significantly
              enhanced the appeal of my listings."
            </div>
          </div>
        </div>
      ),
      backContent: (
        <div className="review-flip-card">
          <div className="reviewImage">
            <img src="/assets/images/card-image.png" alt="card" />
          </div>
          <div className="p-2">
            <div className="clientDetails">
              <img src="/assets/images/customer.jpg" alt="" />
              <div>
                <span className="name">Hannah Schmitt</span>
                <span className="designation">Lead Designer</span>
              </div>
            </div>
            <div className="reviewText">
              Virtual Renovation by Stagobox: "I can't thank Stagobox enough for
              their virtual renovation service. They transformed my outdated
              property into a modern masterpiece with just a few clicks.tagobox
              virtual staging service has been a lifesaver for my real estate
              business. Their attention to detail and ability to create
              realistic virtual furniture placements have significantly enhanced
              the appeal of my listings."
            </div>
          </div>
        </div>
      ),
    },
    {
      index: 3,
      frontContent: (
        <div className="review-flip-card">
          <div className="reviewImage">
            <img src="/assets/images/card-image.png" alt="card" />
          </div>
          <div className="p-2">
            <div className="clientDetails">
              <img src="/assets/images/customer.jpg" alt="" />
              <div>
                <span className="name">Hannah Schmitt</span>
                <span className="designation">Lead Designer</span>
              </div>
            </div>
            <div className="reviewText">
              Virtual Staging with Stagobox: "Stagobox virtual staging service
              has been a lifesaver for my real estate business. Their attention
              to detail and ability to create realistic virtual furniture
              placements have significantly enhanced the appeal of my
              listings.tagobox virtual staging service has been a lifesaver for
              my real estate business. Their attention to detail and ability to
              create realistic virtual furniture placements have significantly
              enhanced the appeal of my listings."
            </div>
          </div>
        </div>
      ),
      backContent: (
        <div className="review-flip-card">
          <div className="reviewImage">
            <img src="/assets/images/card-image.png" alt="card" />
          </div>
          <div className="p-2">
            <div className="clientDetails">
              <img src="/assets/images/customer.jpg" alt="" />
              <div>
                <span className="name">Hannah Schmitt</span>
                <span className="designation">Lead Designer</span>
              </div>
            </div>
            <div className="reviewText">
              Virtual Renovation by Stagobox: "I can't thank Stagobox enough for
              their virtual renovation service. They transformed my outdated
              property into a modern masterpiece with just a few clicks.tagobox
              virtual staging service has been a lifesaver for my real estate
              business. Their attention to detail and ability to create
              realistic virtual furniture placements have significantly enhanced
              the appeal of my listings."
            </div>
          </div>
        </div>
      ),
    },
    {
      index: 4,
      frontContent: (
        <div className="review-flip-card">
          <div className="reviewImage">
            <img src="/assets/images/card-image.png" alt="card" />
          </div>
          <div className="p-2">
            <div className="clientDetails">
              <img src="/assets/images/customer.jpg" alt="" />
              <div>
                <span className="name">Hannah Schmitt</span>
                <span className="designation">Lead Designer</span>
              </div>
            </div>
            <div className="reviewText">
              Virtual Staging with Stagobox: "Stagobox virtual staging service
              has been a lifesaver for my real estate business. Their attention
              to detail and ability to create realistic virtual furniture
              placements have significantly enhanced the appeal of my
              listings.tagobox virtual staging service has been a lifesaver for
              my real estate business. Their attention to detail and ability to
              create realistic virtual furniture placements have significantly
              enhanced the appeal of my listings."
            </div>
          </div>
        </div>
      ),
      backContent: (
        <div className="review-flip-card">
          <div className="reviewImage">
            <img src="/assets/images/card-image.png" alt="card" />
          </div>
          <div className="p-2">
            <div className="clientDetails">
              <img src="/assets/images/customer.jpg" alt="" />
              <div>
                <span className="name">Hannah Schmitt</span>
                <span className="designation">Lead Designer</span>
              </div>
            </div>
            <div className="reviewText">
              Virtual Renovation by Stagobox: "I can't thank Stagobox enough for
              their virtual renovation service. They transformed my outdated
              property into a modern masterpiece with just a few clicks.tagobox
              virtual staging service has been a lifesaver for my real estate
              business. Their attention to detail and ability to create
              realistic virtual furniture placements have significantly enhanced
              the appeal of my listings."
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <section className="flip-card-section" style={{background:"linear-gradient(180deg, #FBFCEE,white)", marginTop:"0px", paddingTop:140}}>
      <div className="container pb-5">
        {!isMobile ? (
          <div className="row g-5 flip-card-container">
            {/* <div className="col-12 col-lg-6 small-flips first"  style={{background:"#f5f5f5", padding:"1rem", marginTop:0, borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
              {renderFlipCard(
                cardData[0].index,
                cardData[0].frontContent,
                cardData[0].backContent
              )}
            </div> */}
            <div className="col-12 col-lg-6 trey"  style={{ marginTop:0}}>
              {/* <Marquee direction="up"> */}
              <div className="row">
                <div className="col-12 small-flips mb-2"  style={{background:"#f5f5f5", padding:"1rem", marginTop:0,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[1].index,
                    cardData[1].frontContent,
                    cardData[1].backContent
                  )}
                </div>

                <div className="col-12 small-flips mb-2"  style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[2].index,
                    cardData[2].frontContent,
                    cardData[2].backContent
                  )}
                </div>
                <div className="col-12 small-flips mb-2" style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[0].index,
                    cardData[0].frontContent,
                    cardData[0].backContent
                  )}
                </div>
                <div className="col-12 small-flips mb-2" style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[4].index,
                    cardData[4].frontContent,
                    cardData[4].backContent
                  )}
                </div>
                {/* copy cards */}
                <div className="col-12 small-flips mb-2"  style={{background:"#f5f5f5", padding:"1rem", marginTop:0,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[1].index,
                    cardData[1].frontContent,
                    cardData[1].backContent
                  )}
                </div>

                <div className="col-12 small-flips mb-2"  style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[2].index,
                    cardData[2].frontContent,
                    cardData[2].backContent
                  )}
                </div>
                <div className="col-12 small-flips mb-2" style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[0].index,
                    cardData[0].frontContent,
                    cardData[0].backContent
                  )}
                </div>
                <div className="col-12 small-flips mb-2" style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[4].index,
                    cardData[4].frontContent,
                    cardData[4].backContent
                  )}
                </div>
              </div>
              {/* </Marquee> */}
            </div>
            <div className="col-12 col-lg-6 trey"  style={{ marginTop:0}}>
              <div className="row">
                <div className="col-12 small-flips mb-2"  style={{background:"#f5f5f5", padding:"1rem", marginTop:0,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[1].index,
                    cardData[1].frontContent,
                    cardData[1].backContent
                  )}
                </div>

                <div className="col-12 small-flips mb-2"  style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[2].index,
                    cardData[2].frontContent,
                    cardData[2].backContent
                  )}
                </div>
                <div className="col-12 small-flips mb-2" style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[0].index,
                    cardData[0].frontContent,
                    cardData[0].backContent
                  )}
                </div>
                <div className="col-12 small-flips mb-2" style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[4].index,
                    cardData[4].frontContent,
                    cardData[4].backContent
                  )}
                </div>
                {/* copy data start */}
                <div className="col-12 small-flips mb-2"  style={{background:"#f5f5f5", padding:"1rem", marginTop:0,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[1].index,
                    cardData[1].frontContent,
                    cardData[1].backContent
                  )}
                </div>

                <div className="col-12 small-flips mb-2"  style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[2].index,
                    cardData[2].frontContent,
                    cardData[2].backContent
                  )}
                </div>
                <div className="col-12 small-flips mb-2" style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[0].index,
                    cardData[0].frontContent,
                    cardData[0].backContent
                  )}
                </div>
                <div className="col-12 small-flips mb-2" style={{background:"#f5f5f5", padding:"1rem", marginTop:5,borderRadius:"12px", boxShadow:"0px 0px 12px 2px rgba(0,0,0,0.1)"}}>
                  {renderFlipCard(
                    cardData[4].index,
                    cardData[4].frontContent,
                    cardData[4].backContent
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Carousel
            autoplay
            dots
            arrows
            autoplaySpeed={3000}
            nextArrow={<ArrowRightOutlined />}
            prevArrow={<ArrowLeftOutlined />}
            className="client-rating-slider"
          >
            {cardData.map((card) => (
              <div key={card?.index}>
                {renderFlipCard(
                  card?.index,
                  card.frontContent,
                  card.backContent
                )}
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </section>
  );
};

export default FlipReviewGrid;
